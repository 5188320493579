'use client';

import React, {
    useEffect,
    useState,
} from 'react';
import ResponsiveMenu from '@frontend/jetlend-web-ui/src/components/ResponsiveMenu/ResponsiveMenu';
import { usePathname } from 'next/navigation';
import ConnectedToastsList from '@frontend/jetlend-web-ui/src/components/Toasts/ConnectedToastsList';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import {
    isMobile,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import Link from 'next/link';
import Image from '@app/components/Image/Image';
import LoginButton from '@app/components/LoginButton/LoginButton';
import RegistrationButton from '@app/components/RegistrationButton/RegistrationButton';
import { IPagesMenuApiModel } from '@app/models/common/pages';
import ConnectedSelectUserButton from '../ConnectedSelectUserButton/ConnectedSelectUserButton';
import ConnectedLogoutButton from '../ConnectedLogoutButton/ConnectedLogoutButton';
import { accountInfoHandler } from '@app/ducks/common/account';
import LayoutHeaderMenuItem from './LayoutHeaderMenuItem';
import ConnectedUserSidePanel from '../ConnectedUserSidePanel/ConnectedUserSidePanel';

export interface IProps {
    /**
     * Список элементов навигации
     */
    navigation: IPagesMenuApiModel[];
}

const isNewYearBonusEnabled = process.env.NEXT_PUBLIC_NEW_YEAR_BONUS === 'true';

function normalizeUrl(url: string) {
    return url.replace(/^\/|\/$/g, '');
}

function findActiveMenuItemIndex(navigation: IPagesMenuApiModel[], pathname: string) {
    // Проверяем, есть ли активный элемент меню с полным совпадением URL
    const exactMatchIndex = navigation.findIndex((item) => {
        const normalizedPath = normalizeUrl(pathname);
        const normalizedUrl = normalizeUrl(item.url);

        return normalizedPath === normalizedUrl;
    });

    if (exactMatchIndex !== -1) {
        return exactMatchIndex;
    }

    // Проверяем, есть ли активный элемент меню с частичным совпадением URL
    const partialMatchIndex = navigation.findIndex((item) => {
        const normalizedPath = normalizeUrl(pathname);
        const normalizedUrl = normalizeUrl(item.url);

        const pathSegments = normalizedPath.split('/');
        const urlSegments = normalizedUrl.split('/');

        if (pathSegments.length > 0 && urlSegments.length > 0) {
            return pathSegments[0] === urlSegments[0];
        }

        return false;
    });

    return partialMatchIndex;
}

/**
 * Компонент хедера страницы
 */
export default function LayoutHeader ({
    navigation,
}: IProps) {
    const pathname = usePathname();
    const [v, setV] = useState(0);
    const [account] = useSimpleApiHandler(accountInfoHandler);

    const { width } = useWindowSize();
    const isMobileScreen = isMobile(width);

    const hasAccount = Boolean(account && account.user_id);

    const logoSrc = isNewYearBonusEnabled ? '/next/assets/logo-new-year.svg' : '/next/assets/logo.svg';

    const activeMenuItemIndex = findActiveMenuItemIndex(navigation, pathname);

    // Upgrade version when pathname provided to rerender items and highlight it
    useEffect(() => {
        setV(v => v + 1);
    }, [ pathname ]);

    return (
        <>
            <ResponsiveMenu
                fixed
                logo={(
                    <Link href="/">
                        <Image src={logoSrc} width={isNewYearBonusEnabled ? 200 : 150} height={50} unoptimized alt="" />
                    </Link>
                )}
                smallLogo={(
                    <Link href="/">
                        <Image src={logoSrc} width={isNewYearBonusEnabled ? 153 : 110} height={34} unoptimized alt="" />
                    </Link>
                )}
                actions={hasAccount
                    ? (
                        <>
                            <ConnectedSelectUserButton account={account} />
                            <ConnectedLogoutButton
                                type={isMobileScreen ? 'button' : 'icon'}
                                account={account}
                            />
                        </>
                    )
                    : (
                        <>
                            <LoginButton
                                type="light"
                                block
                            />
                            <RegistrationButton
                                type="default"
                                block
                            />
                        </>
                    )
                }
            >
                {navigation.map((item, index) => (
                    <LayoutHeaderMenuItem
                        key={`${item.url}--${v}`}
                        active={activeMenuItemIndex === index}
                        {...item}
                    />
                ))}
            </ResponsiveMenu>

            <ConnectedToastsList />
            {hasAccount &&
                <ConnectedUserSidePanel account={account} />
            }
        </>
    );
};
