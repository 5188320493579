'use client';

import DropdownActions from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';
import Button, { ButtonProps } from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import React from 'react';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import useEventCallback from '@frontend/jetlend-core/src/hooks/useEventCallback';
import { startRegistration } from '@app/ducks/common/registration';
import { ClientType } from '@app/models/common/common';

import styles from './RegistrationButton.module.scss';
import { sendEvent } from '@app/ducks/common/analytics';

/**
 * Кнопка регистрации
 */
export default function RegistrationButton(props: ButtonProps) {
    const startRegistrationAction = useActions(startRegistration);
    const sendEventAction = useActions(sendEvent);

    const didStartClicked = useEventCallback((clientType: ClientType) => {
        // Отправляем события для аналитики
        sendEventAction('open-registration--header');
        sendEventAction(`${clientType}--open-registration--header`);

        // Открываем регистрацию
        startRegistrationAction(clientType);
    });

    const didInvestorStartClicked = useEventCallback(() => didStartClicked(ClientType.INVESTOR));
    const didBorrowerStartClicked = useEventCallback(() => didStartClicked(ClientType.BORROWER));
    const didPartnerStartClicked = useEventCallback(() => didStartClicked(ClientType.PARTNER));

    return (
        <DropdownActions
            placement="bottom"
            size="small"
            hover
            withoutArrow={false}
            button={(
                <Button
                    text="Зарегистрироваться"
                    noSpacing
                    width={130}
                    {...props}
                />
            )}
        >
            <DropdownActions.Button onClick={didInvestorStartClicked} className={styles['button']}>
                Инвестор
            </DropdownActions.Button>
            <DropdownActions.Button onClick={didBorrowerStartClicked} className={styles['button']}>
                Предприниматель
            </DropdownActions.Button>
            <DropdownActions.Button onClick={didPartnerStartClicked} className={styles['button']}>
                Партнер
            </DropdownActions.Button>
        </DropdownActions>
    );
};
