import {
    useCallback,
    useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { IModalHandler } from '@core/ducks/modal';
import { useActions } from '@core/hooks/useActions';

type HookReturnType = [
    boolean,
    () => void,
    () => void
]

/**
 * Хук для управления одиночным модальным окном {@link modalHandler} или {@link modalFormHandler} внутри компонента.
 * Смотит на location.hash, если он совпадает с hashId - открывает модалку.
 *
 * @param handler Хендлер модального окна.
 * @param hashId Хеш, наличие которого определяет открыто окно, или нет
 * @returns Возвращает флаг открытия модального окна, и функции для открытия и закрытия.
 * @category Hooks
 *
 * @see {@link useIsModalOpen}
 * @see {@link useIsModalOpenWithKey}
 * @see {@link useModalHandlerWithKey}
 *
 * @example
 * const [ isOpen, open, close ] = useModalHandler(sprintCreateModalHandler);
 */
export function useHashModal(
    handler: IModalHandler,
    hashId: string
): HookReturnType {
    const [open, close] = useActions([handler.open, handler.close]);
    const isOpen = useSelector(handler.isOpen);

    useEffect(() => {
        function handleHashChange() {
            if (location.hash?.toLowerCase() === `#${hashId.toLowerCase()}`) {
                open();
            } else {
                close();
            }
        }

        window.addEventListener('hashchange', handleHashChange);

        handleHashChange();

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [hashId]);

    const didModalClosed = useCallback(() => {
        if (location.hash?.toLowerCase() === `#${hashId.toLowerCase()}`) {
            location.hash = '';
        }

        close();
    }, [hashId]);

    return [isOpen === true, open, didModalClosed];
}