'use client';

import React, {
    useCallback,
    useMemo,
} from 'react';
import ResponsiveMenuDropdownAction from '@frontend/jetlend-web-ui/src/components/ResponsiveMenu/ResponsiveMenu.DropdownAction';
import ResponsiveMenuButton from '@frontend/jetlend-web-ui/src/components/ResponsiveMenu/ResponsiveMenu.Button';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import {
    IPagesMenuApiModel,
    IPagesSubmenuItem,
} from '@app/models/common/pages';
import { sendEvent } from '@app/ducks/common/analytics';

interface IDropdownListItem extends IPagesSubmenuItem {
    /**
     * Href
     */
    href?: string;
    /**
     * onClick callback
     */
    didClicked: () => void;
}

export interface IProps extends IPagesMenuApiModel {
    /**
     * Активный элемент меню
     */
    active?: boolean;
}

/**
 * Компонент элемента из списка меню
 */
export default function LayoutHeaderMenuItem({
    title,
    url,
    submenu_items,
    active,
}: IProps) {
    const sendEventAction = useActions(sendEvent);

    const sendEventCallback = useCallback((url: string) => {
        sendEventAction(`site-header--${url}`);
    }, [ sendEventAction ]);

    const didClicked = useCallback(() => {
        sendEventCallback(url);
    }, [ url, sendEventCallback ]);

    const dropdownListData = useMemo<IDropdownListItem[]>(() => submenu_items?.map(item => ({
        ...item,
        href: item?.url,
        didClicked: () => sendEventCallback(item?.url),
    })), [ submenu_items, sendEventCallback ]);

    return (
        <ResponsiveMenuDropdownAction
            href={url}
            title={title}
            active={active}
            onClick={didClicked}
        >
            {dropdownListData?.map(item => (
                <ResponsiveMenuButton
                    key={item.title}
                    href={item.href}
                    title={item.title}
                    onClick={item.didClicked}
                />
            ))}
        </ResponsiveMenuDropdownAction>
    );
};
