import SelectUserButton from '@frontend/jetlend-web-ui/src/components/SelectUserButton/SelectUserButton';
import React, {
    useCallback,
    useEffect,
} from 'react';
import {
    isMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import AccountTypeBadge from '@frontend/jetlend-web-ui/src/components/AccountTypeBadge/AccountTypeBadge';
import { useDispatch } from 'react-redux';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import { userSidePanelHandler } from '@frontend/jetlend-core/src/features/CabinetUserSidePanel/ducks';
import convertFullNameToShortName from '@frontend/jetlend-core/src/formatters/convertFullNameToShortName';
import {
    CLIENT_TYPE_CABINET_TARIFF_URLS,
    CLIENT_TYPE_NAMES,
} from '@app/models/common/common';
import { fetchUser } from '@app/ducks/common/account';
import { IAccountInfoApiModel } from '@app/models/common/account';


export interface IProps {
    /**
     * Объект с информацией об аккаунте
     */
    account: IAccountInfoApiModel;
}

/**
 * Компонент отображения кнопки текущего пользователя.
 */
export default function ConnectedSelectUserButton ({ account }: IProps) {
    const { width: screenWidth } = useWindowSize();
    const mobile = isMobile(screenWidth);
    const dispatch = useDispatch();

    // Не используем useMemo, т.к. операция получения имени простая
    const accountName = (() => {
        if (!account?.name) {
            return undefined;
        }

        const { name } = account;
        if (isTablet(screenWidth) || mobile) {
            return convertFullNameToShortName(name);
        }

        return name;
    })();

    useEffect(() => {
        dispatch(fetchUser());
    }, [ dispatch ]);

    const openUserSidePanel = useActions(userSidePanelHandler.open);

    const didAccountClicked = useCallback(() => openUserSidePanel(), [ openUserSidePanel ]);

    return (
        <SelectUserButton
            name={accountName}
            defaultUserName={CLIENT_TYPE_NAMES[account?.client_type]}
            loading={!accountName}
            onClick={didAccountClicked}
        >
            <AccountTypeBadge href={CLIENT_TYPE_CABINET_TARIFF_URLS[account?.client_type]} type={account?.account_type} />
        </SelectUserButton>
    );
};