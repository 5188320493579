import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/jetlend_react_lib/packages/jetlend-web-ui/src/ui/TooltipIcon/Tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/SFProDisplay/SFProDisplay.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./Black/SFProDisplay-Black.woff2\",\"weight\":\"900\"},{\"path\":\"./Bold/SFProDisplay-Bold.woff2\",\"weight\":\"700\"},{\"path\":\"./Heavy/SFProDisplay-Heavy.woff2\",\"weight\":\"800\"},{\"path\":\"./Light/SFProDisplay-Light.woff2\",\"weight\":\"300\"},{\"path\":\"./Medium/SFProDisplay-Medium.woff2\",\"weight\":\"500\"},{\"path\":\"./Regular/SFProDisplay-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"./SemiBold/SFProDisplay-SemiBold.woff2\",\"weight\":\"600\"},{\"path\":\"./Thin/SFProDisplay-Thin.woff2\",\"weight\":\"100\"},{\"path\":\"./Ultralight/SFProDisplay-Ultralight.woff2\",\"weight\":\"200\"}],\"display\":\"swap\",\"variable\":\"--default-font-name\"}],\"variableName\":\"SFProDisplayFont\"}");
;
import(/* webpackMode: "eager" */ "/app/jetlend_react_lib/packages/jetlend-web-ui/src/components/Wizard/wizardBreadCrumbButton.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LayoutFooter/LayoutFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutFooterPortalProvider"] */ "/app/src/components/LayoutFooter/LayoutFooterSectionPortal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/LayoutHeader/LayoutHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/RegistrationModal/RegistrationModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/YandexMetrika/YandexMetrikaClient.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/events/snezhnaya/DedMoroz/DedMoroz.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/investments/InvestmentsBrokersModal/InvestmentsBrokersModalClient.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/icons/app-store-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/google-play-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/yandex-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/store/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/main.scss");
