import React from 'react';
import { IClassName } from '@frontend/jetlend-core/src/models/common';
import { Link } from 'react-router-dom';
import { useEventCallback } from '@mui/material';
import { DefaultComponents } from '../../common/DefaultComponents';
import styles from './responsiveMenu.module.scss';
import { buildClassNames } from '../../utils/classNameUtils';
import { useResponsiveMenuContext } from './ResponsiveMenu.context';

export interface IProps extends IClassName {
    /**
     * Заголовок пункта меню.
     */
    title: string|React.ReactNode;
    /**
     * Ссылка пункта меню.
     */
    href: string;
    /**
     * Поведение ссылки для индексации.
     */
    rel?: string;
    /**
     * Активен ли пункт меню в данный момент или нет.
     */
    active?: boolean;
    /**
     * @event
     * Событие клика по ссылке.
     */
    onClick?: () => void;
}

/**
 * Компонент обычной кнопки пункта меню.
 * Должно применяться совместно с компонентом {@link ResponsiveMenu}.
 */
export default function ResponsiveMenuButton({
    title,
    href,
    rel,
    active,
    onClick,
    className: externalClassName,
}: IProps) {
    const buttonClassName = buildClassNames(styles, [
        'button',
        active && 'button--active',
    ], [ externalClassName ]);

    const { closeBurgerMenu } = useResponsiveMenuContext() ?? {};
    const didLinkClicked = useEventCallback(() => {
        // При клике на пункт меню закрываем бургерное меню
        closeBurgerMenu();

        onClick?.();
    });

    const isReactRouterLink = DefaultComponents.Link === Link;
    // Для корректной типизации
    const LinkComponent = isReactRouterLink
        ? Link
        : DefaultComponents.Link;

    return (
        <LinkComponent
            className={buttonClassName}
            // Синтаксис React Router немного отличается от остальных
            href={isReactRouterLink ? undefined : href}
            to={isReactRouterLink ? href : undefined}
            rel={rel}
            onClick={didLinkClicked}
        >
            {title}
        </LinkComponent>
    );
};
