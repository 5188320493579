'use client';

import React, { useCallback } from 'react';
import Button from '@frontend/jetlend-web-ui/src/ui/inputs/Button/Button';
import { useActions } from '@frontend/jetlend-core/src/hooks/useActions';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './InvestmentsBrokerCard.module.scss';
import { sendEvent } from '@app/ducks/common/analytics';
import { IBrokerModel } from '@app/models/common/common';

export interface IProps extends IBrokerModel {
    /**
     * Размер карточки брокера.
     */
    size?: 'small'|'default';
}

/**
 * Компонент карточки брокера.
 */
export default function InvestmentsBrokerCard({
    id,
    title,
    icon,
    url,
    size = 'default',
}: IProps) {
    const sendEventAction = useActions(sendEvent);
    const didLinkClicked = useCallback(() => {
        sendEventAction(`investments--broker-${id}-click`);
    }, [ id, sendEventAction ]);

    const containerClassName = buildClassNames(styles, [
        'container',
        `container--size-${size}`,
    ]);

    return (
        <div className={containerClassName}>
            <div className={styles['header']}>
                <div className={styles['icon']}>
                    <img src={icon} alt="" />
                </div>
                <div className={styles['title']}>{title}</div>
            </div>
            <Button
                external
                href={url}
                text="Оставить заявку"
                className={styles['button']}
                containerClassName={styles['button__container']}
                onClick={didLinkClicked}
            />
        </div>
    );
}