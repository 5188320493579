'use client';

import Modal from '@frontend/jetlend-web-ui/src/ui/modals/Modal';
import React from 'react';
import { useHashModal } from '@frontend/jetlend-core/src/hooks/useHashModal';
import InvestmentsBrokerCard from '../InvestmentsBrokersList/InvestmentsBrokerCard/InvestmentsBrokerCard';
import styles from './InvestmentsBrokersModalClient.module.scss';
import { investmentsBrokersModalHandler } from '@app/ducks/investments';
import { IBrokerModel } from '@app/models/common/common';

export interface IProps {
    /**
     * Заголовок модального окна.
     */
    title: string;
    /**
     * Список брокеров для отображения.
     */
    brokers?: IBrokerModel[];
}

/**
 * Компонент клиентского модального окна со списком брокеров.
 */
export default function InvestmentsBrokersModalClient({
    title,
    brokers,
}: IProps) {
    const [ isOpen, , close ] = useHashModal(investmentsBrokersModalHandler, 'modal-brokers');

    return (
        <Modal
            title={title}
            noBorder
            closable
            size="wide"
            isOpen={isOpen}
            onClosed={close}
        >
            <div className={styles['brokers']}>
                {brokers?.map?.(broker => (
                    <InvestmentsBrokerCard
                        key={broker.id}
                        size="small"
                        {...broker}
                    />
                ))}
            </div>
        </Modal>
    );
}

